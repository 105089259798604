.privacy-policy-container{
  margin: 1rem 2rem 1rem;

  p{
    font-size: 1.6rem;
  }

  li{
    font-size: 3rem;
  }

  strong{
    font-size: 1.7rem;
    color: #374665;
  }

  h1{
    color: #374665;
  }
}