.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: whitesmoke;
    padding: 0 10px;

    .not-found {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        max-width: 500px;
        background-color: white;
        border-radius: 20px;
        padding-top: 28px;
        padding-bottom: 28px;
    }
  
    h1 {
      font-size: 32px;
      font-weight: 700;
      color: #DB0001;
      margin-top: 20px;
    }
    
    p {
        margin-bottom: 20px;
        font-size: medium;
    }
  
    button {
      padding: 8px 16px;
      font-size: 1rem;
      border: none;
      background-color: #DB0001;
      color: white;
      border-radius: 8px;
      font-size: medium;
    }
  }
  
  @media (min-width: 768px) {
    .not-found {
        width: 50%;
    }
}

@media (min-width: 1024px) {
    .not-found {
        width: 50%;
    }
}