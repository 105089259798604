@import "../../styles/utils";
@import "../../styles/antd-custom";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 8rem;
  padding: 0 18rem 0 2rem;
  z-index: 1;
  background: #DB0001;

  h1 {
    color: white;
    font-size: 44px;
    font-weight: 700;
    margin-left: 200px;

    :hover {
      text-decoration: white !important;
    }
  }

  @include large {
    height: 7.4rem;
    padding: 0 3rem 0 2rem;

    .headerNavigation {
      right: 100vw;
    }

    &.expanded {
      .headerNavigation {
        right: 0;
      }
    }
  }
}

.closeIcon {
  display: none;
}

.barsIcon {
  display: none;
  cursor: pointer;
  transform: scale(1.2);

  @include large {
    display: block;
  }
}

.headerNavigation {
  transition: right .2s linear;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    margin-right: 3rem;
    font-weight: bold;

    @include large {
      justify-content: space-between;
      margin-top: 20px;
    }

    @include small {
      display: block;
      margin: 0 auto; /* This centers the ul horizontally */
      width: fit-content; /* Adjust width to fit content */
      padding: 0;
      margin-top: 70px;
    }

    li {
      margin-left: 5rem;
      cursor: pointer;

      @include small {
        text-align: center;
        margin: 35px 0;
      }

      a, a:hover {
        font-size: 20px;
        color: white;
      }
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: white !important;

      @include small {
        margin-left: 0;
      }
    };
  }

  @include large {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 3rem;
    margin: 0;
    z-index: 9999;
    background-color: #DB0001;

    .closeIcon {
      display: block;
      cursor: pointer;
      margin-left: auto;
      transform: scale(1.2);
    }
  }
}

.headerDropdownMenuOverlay {
  width: 12rem;
  margin-left: 80px !important;
  @include large {
    z-index: 9999;
  }
}

.phoneHeader {
  height: 30px;
  background: #DB0001;
  display: flex;
  justify-content: end;
  align-items: center;
  font-weight: bold;
  color: white;

  a {
    color: inherit; /* Maintain the current text color */
    text-decoration: none; /* Remove the underline */
    font-size: 12px;
    align-content: center;
  }

  a:hover {
    text-decoration: underline; /* Optionally underline on hover */
    color: #ffaeae;
  }

  p{
    margin-bottom: 0;
    align-content: center;
  }

  @include medium {
    justify-content: center;
  }

  @include small {
    justify-content: center;
    p {
      font-size: 10px;
    }
  }

  .phoneHeaderContent {
    display: flex;
    margin-right: 30px;

    img {
      width: 22px;
      margin-bottom: 0;
      margin-right: 4px;
    }

    @include small {
      margin-right: 15px;

      img {
        width: 22px;
        height: 100%;
        margin-right: 5px !important;
        margin-bottom: 0 !important;
      }
    }
  }
}
